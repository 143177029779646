<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="250px" class="border-r-2">
        <div class="mt-5 px-4 py-6 rounded-lg">
          <i class="pi pi-file mb-4" style="font-size: 3rem"></i>
          <h2 class="title-font font-medium text-2xl text-gray-900">
            專業證照獎勵
          </h2>
          <p class="leading-relaxed">ProLicense</p>
        </div>
        <ul>
          <li class="w-full">
            <a
              @click="changePage(0)"
              :class="{ active: pageIndex == 0 }"
              class="adminLink text-lg inline-block p-4 w-full text-gray-900 bg-white rounded-r-lg focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-gray-700 dark:text-white"
              aria-current="page"
              >待審核清單</a
            >
          </li>
          <li class="w-full">
            <a
              @click="changePage(1)"
              :class="{ active: pageIndex == 1 }"
              class="adminLink text-lg inline-block p-4 w-full bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              aria-current="page"
              >歷史清單</a
            >
          </li>
          <li class="w-full">
            <a
              @click="changePage(3)"
              :class="{ active: pageIndex == 3 }"
              class="adminLink text-lg inline-block p-4 w-full bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >資料匯入</a
            >
          </li>
          <li class="w-full">
            <a
              @click="changePage(2)"
              :class="{ active: pageIndex == 2 }"
              class="adminLink text-lg inline-block p-4 w-full bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >顯示設定</a
            >
          </li>
          <li class="w-full">
            <a
              @click="goBack()"
              class="adminLink text-lg inline-block p-4 w-full bg-gray-100 hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >返回上一頁</a
            >
          </li>
        </ul>
      </el-aside>
      <el-main>
        <Verify v-if="pageIndex == 0" />
        <List v-else-if="pageIndex == 1" />
        <Setting v-else-if="pageIndex == 2" />
        <Import v-else-if="pageIndex == 3"></Import>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import Verify from "./Verify.vue";
import List from "./List.vue";
import Setting from "./Setting.vue";
import Import from "./Import.vue";

export default {
  components: {
    List,
    Setting,
    Verify,
    Import,
  },
  setup() {
    const router = useRouter();
    const goBack = () => {
      router.push({ path: "/sladmin/awardlist" });
    };

    const pageIndex = ref(0);

    const changePage = (n) => {
      pageIndex.value = n;
    };

    return { pageIndex, changePage, goBack };
  },
};
</script>

<style>
.adminLink {
  cursor: pointer;
}
</style>
