<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="250px" class="border-r-2">
        <div class="mt-5 px-4 py-6 rounded-lg">
          <i class="pi pi-book mb-4" style="font-size: 3rem"></i>
          <h2 class="title-font font-medium text-2xl text-gray-900">
            課程評價
          </h2>
          <p class="leading-relaxed">Course Feedback</p>
        </div>
        <ul>
          <li class="w-full">
            <a
              @click="changePage(0)"
              :class="{ active: pageIndex == 0 }"
              class="adminLink text-lg inline-block p-4 w-full hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >待審查評價</a
            >
          </li>
          <li class="w-full">
            <a
              @click="changePage(1)"
              :class="{ active: pageIndex == 1 }"
              class="adminLink text-lg inline-block p-4 w-full hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >歷史紀錄</a
            >
          </li>
          <li class="w-full">
            <a
              @click="changePage(2)"
              :class="{ active: pageIndex == 2 }"
              class="adminLink text-lg inline-block p-4 w-full hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >評價回收桶</a
            >
          </li>
          <li class="w-full">
            <a
              @click="goBack()"
              class="adminLink text-lg inline-block p-4 w-full bg-gray-100 hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >返回上一頁</a
            >
          </li>
        </ul>
      </el-aside>
      <el-main>
        <List v-if="pageIndex == 0" />
        <History v-else-if="pageIndex == 1" />
        <Trash v-else-if="pageIndex == 2" />
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import List from "./List.vue";
import History from "./History.vue";
import Trash from "./Trash.vue";

const pageIndex = ref(0);
const changePage = (index) => {
  pageIndex.value = index;
};

const router = useRouter();
const goBack = () => {
  router.push({
    path: "/sladmin/",
  });
};
</script>
